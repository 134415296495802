@import "../mixins";
@import "../variables";

.logo{
  @include display-flex();
  width:  80%;
  height: 80%;
  border-radius: 50%;
}

.my-card{
  max-width: 200vw;
}

.card-main{
  width: 18rem;
  background-color: darkgray;
}

.card-header  {
  color: yellow;
}

.inputTextContainer{
  margin-bottom: 5vh;
  width:100%;
}

.error-msg{
  font-size: 6px;
}

.new_sale_container {
  height: 12vh;
  transform: translateY(15%);
}

.sales_finder_main_container{
  
  border: 1px solid blue;
  border-radius: 5px;
  margin-top: 5px;
}

.sales_finder_container{
  height: 14vh;
  transform: translateY(15%);
}


.listContainer{
  height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  border: 1px solid blue;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 3vh;  
}

.spacer{
  height: 5vh;
}


.ClientsHeadItems_1{
  text-align: center;
  width: 7vw;
  color:black;
}
.ClientsHeadItems_2{
  text-align: right;
  color:black;
  width: 12vw;
}
.ClientsHeadItems_3{
  text-align: center;
  width: 13vw;
  color:black;
}
.ClientsHeadItems_4{
  @include align-items-center();
  width: 25%;
}
.ClientsHeadItems_5{
  text-align: left;
  color:black;
  width: 12vw;
}
.ClientsHeadItems_6{
  text-align: left;
  color:black;
  width: 20vw;
  height: 10vh;
  
}
.ClientsHeadItems_7{
  text-align: left;
  color:black;
  width: 40vw;
  height: 10vh;
  
}

.ClientsHeadItems_8{
  text-align: center;
  width: 20vw;
  color:black;
}

.ClientResultLine{
  @include align-items-center();
  @include display-flex();
  width: 100%;
  height: 10vh;
}

.logo{
  width:  50%;
}

.ClientsOptions{
  text-align: center;
  width: 10vw;
  color:black;
}
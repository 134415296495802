@import "../mixins";
@import "../variables";

.ClientsFinder{
  width: 70%;
  background-color: #021583;
  height: 35vh;
  border-radius: 5px;
  margin-bottom: 1vh;
}

.FinderOptions{
  @include display-flex();
  @include align-items-center();
  width: 100%;
  height: 9.5vh;
  border-radius: 5px;
  margin-bottom: 1vh;
}

.FinderField_1{
  @include align-items-center();
  width: 30%;
}

.FinderField_2{
  @include align-items-center();
  @include display-flex();
  width: 70%;
}

.inputRadio{
  border: 0px;
  width: 30px;
  height:21px;
}

.inputTextContainer{
  margin-bottom: 5vh;
  margin-left: 100px;
  width:50%;
}

.FinderCommands{
  @include display-flex();
}

.navClientsBtn{
  @include display-flex();
  margin-left: 5vw;
}

.navClientsBtnContainer{
  margin-left: 2vw;
  margin-right: 20px;
}


@import "../../mixins";
@import "../../variables";

.logo{
  @include display-flex();
  width:  80%;
  height: 80%;
  border-radius: 50%;
}

.my-card{
  max-width: 200vw;
}

.card-main{
  width: 18rem;
  background-color: darkgray;
}

.card-header  {
  color: yellow;
}

.inputTextContainer{
  margin-bottom: 5vh;
  width:100%;
}

.error-msg{
  font-size: 6px;
}

.new_sale_container {
  height: 35vh;
  transform: translateY(15%);
}

.sales_finder_main_container{
  
  border: 1px solid blue;
  border-radius: 5px;
  margin-top: 5px;
}

.sales_finder_container{
  height: 10vh;
  transform: translateY(15%);
}

.colId{
  width: 100%;
  text-align: center;
  color: black;
}

.listContainerOptions{
  height: 8vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  border: 1px solid blue;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 3vh;  
}

.listContainer{
  height: 30vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  border: 1px solid blue;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 3vh;  
}

.spacer{
  height: 5vh;
}

.icon{
  @include display-flex();
  width:  30%;
  margin-left: 40%;
}
@import "../mixins";
@import "../variables";

.logo{
  @include display-flex();
  width:  80%;
  height: 80%;
  border-radius: 50%;
}
.brand{
  width: 5%;
}

@import "../mixins";
@import "../variables";

.ClientsHeadList{
  @include display-flex();
  @include align-items-center();
  width: 70%;
  background-color: #021583;
  height: 10vh;
  border-radius: 5px;
}
.ClientsBodyList{
  @include align-items-center();
  width: 70%;
  background-color: #1f329b;
  height: 50vh;
  margin-top: 2vh;
  margin-bottom: 5vh;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: lighter;
}
.ClientsHeadItems_1{
  @include align-items-center();
  width: 15%;
}
.ClientsHeadItems_2{
  text-align: left;
  width: 55%;
}
.ClientsHeadItems_3{
  @include align-items-center();
  width: 15%;
}
.ClientsHeadItems_4{
  @include align-items-center();
  width: 25%;
}
.ClientResultLine{
  @include align-items-center();
  @include display-flex();
  width: 100%;
  height: 10vh;
}
.ClientsOptions{
  @include align-items-center();
  @include display-flex();
  width: 20%;
}
.logo{
  @include display-flex();
  width:  100%;
}

.ClientsHeadItems_5{
  @include align-items-center();
  width: 80%;
}

.ClientsHeadItems_6{
  @include align-items-center();
  width: 20%;
}

.logo2{
  @include display-flex();
  width:  50%;
}
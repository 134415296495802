@import "../mixins";
@import "../variables";

.loginTitle {
  @include display-flex();
  @include justify-content-center();
  font-size: 48px;
  margin-bottom: $main-margin;
}
.loginLogo{
  @include display-flex();
  width:  20%;
  height: 20%;
  border-radius: 50%;
}
.loginLogoContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "../../mixins";
@import "../../variables";

.logo{
  @include display-flex();
  width:  80%;
  height: 80%;
  border-radius: 50%;
}

.my-card{
  max-width: 200vw;
}

.card-main{
  width: 18rem;
  background-color: darkgray;
}

.card-header  {
  color: yellow;
}

.inputTextContainer{
  margin-bottom: 5vh;
  width:100%;
}

.error-msg{
  font-size: 6px;
}

.spacer {
  height: 120px;
}

.listContainer{
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}

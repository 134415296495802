* {
  font-weight: 700;
  color: #f7f9fc;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(../src/assets/BG.svg);
}
